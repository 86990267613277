import React, { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import config from "../../../core/frontend/components/config";
import { theme } from "../theme/theme";
import ErrorBoundary from "../components/ErrorBoundary";
import { SnackbarProvider } from "../components/SnackbarProvider";
import { APIProvider } from "../components/APIProvider";
import { GoogleMapsProvider } from "../components/GoogleMapsProvider";
import { IsAdminProvider } from "../components/IsAdminProvider";
import { WaitForBackendAwakeWrapper } from "../components/WaitForBackendAwakeWrapper";
import { BackendPingWrapper } from "../components/BackendPingWrapper";
import routes from "./routes";

const router = createBrowserRouter(routes, {
  basename: config.REACT_APP_ROUTER_BASENAME,
});

function MainFrontendApp() {
  useEffect(() => {
    if (
      window.location.pathname === "/" &&
      config.REACT_APP_ROUTER_BASENAME !== "/"
    ) {
      window.location.pathname = config.REACT_APP_ROUTER_BASENAME;
    }
  }, []);

  let app = <RouterProvider router={router} />;

  const wrappers = [
    IsAdminProvider,
    WaitForBackendAwakeWrapper,
    APIProvider,
    BackendPingWrapper,
    SnackbarProvider,
    ...(config.REACT_APP_GOOGLE_MAPS_API_KEY ? [GoogleMapsProvider] : []),
    (props) => <ThemeProvider theme={theme}>{props.children}</ThemeProvider>,
    (props) => (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        {props.children}
      </LocalizationProvider>
    ),
    (props) => (
      <DndProvider backend={HTML5Backend}>{props.children}</DndProvider>
    ),
    ErrorBoundary,
    (props) => (
      <Auth0Provider
        domain={config.REACT_APP_AUTH0_DOMAIN}
        clientId={config.REACT_APP_AUTH0_CLIENT_ID}
        authorizationParams={{
          redirect_uri: new URL(
            config.REACT_APP_ROUTER_BASENAME,
            window.location.href
          ).href,
          scope: "read:current_user update:current_user_metadata email profile",
          audience: config.REACT_APP_AUTH0_AUDIENCE,
        }}
        cacheLocation='localstorage'
      >
        {props.children}
      </Auth0Provider>
    ),
  ];

  wrappers.forEach((Wrapper) => {
    app = <Wrapper>{app}</Wrapper>;
  });

  return app;
}

export default MainFrontendApp;
