import React, { lazy, Suspense } from "react";
import { PageWrapperWithMenu } from "../elements/PageWrapperWithMenu";

const Loadable = (loader) => {
  const Component = lazy(loader);
  return () => (
    <Suspense fallback={<PageWrapperWithMenu />}>
      <Component />
    </Suspense>
  );
};

const routes = [
  { path: "/", element: Loadable(() => import("../pages/HomePage"))() },
  {
    path: "resumes",
    element: Loadable(() =>
      import("../../../resume_builder/frontend/resume_list/ResumeListPage")
    )(),
  },
  {
    path: "resume/start",
    element: Loadable(() =>
      import("../../../resume_builder/frontend/ResumeStartPage")
    )(),
  },
  {
    path: "resume/:resumeId/*",
    element: Loadable(() =>
      import("../../../resume_builder/frontend/resume_editor/ResumeEditorPage")
    )(),
  },
  {
    path: "resume_prompt_configuration",
    element: Loadable(() =>
      import(
        "../../../resume_builder/frontend/EditResumePromptConfigurationPage"
      )
    )(),
  },
  {
    path: "terms_and_conditions",
    element: Loadable(() => import("../pages/TermsAndConditionsPage"))(),
  },
  {
    path: "charts/",
    element: Loadable(() =>
      import("../../prompt_chart/frontend/ChartListPage")
    )(),
  },
  {
    path: "charts/:chartId",
    element: Loadable(() =>
      import("../../prompt_chart/frontend/ChartEditPage")
    )(),
  },
  {
    path: "resume_configuration",
    element: Loadable(() =>
      import(
        "../../../resume_builder/frontend/resume_configuration/ResumeConfigurationPage"
      )
    )(),
  },
];

export default routes;
