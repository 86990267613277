import axios from "axios";
import { axiosETAGCache } from "axios-etag-cache";

let globalResumeSchema = null;

// Apply the axios ETAG interceptor
const axiosWithETAGCache = axiosETAGCache(axios);
const api = {
  async getResumeSchema() {
    if (globalResumeSchema) {
      return globalResumeSchema;
    }
    const response = await axiosWithETAGCache.get(`/resume/schema`);
    globalResumeSchema = response.data;
    return response.data;
  },

  async getResumes(tableQuery) {
    const queryParams = {
      query: JSON.stringify(tableQuery),
    };

    const response = await axiosWithETAGCache.get(`/resume`, {
      params: queryParams,
    });

    return response.data;
  },

  async createNewResume() {
    const newResume = {};

    const response = await axios.post(`/resume`, newResume);

    return response.data;
  },

  async getResume(resumeId) {
    const queryParams = {};

    const response = await axiosWithETAGCache.get(`/resume/${resumeId}`, {
      params: queryParams,
    });

    return response.data;
  },

  async saveResume(resume) {
    const response = await axios.put(`/resume/${resume._id}`, resume);
    return response.data;
  },

  async deleteResume(resumeId) {
    const response = await axios.delete(`/resume/${resumeId}`);

    return response.data;
  },

  async getJobTitles(query) {
    const queryParams = {
      query: query,
    };

    const response = await axiosWithETAGCache.get(`/job_title`, {
      params: queryParams,
    });

    return response.data;
  },

  async getCompanyNames(query) {
    const queryParams = {
      query: query,
    };

    const response = await axiosWithETAGCache.get(`/company_name`, {
      params: queryParams,
    });

    return response.data;
  },

  async getResumePromptConfiguration() {
    const response = await axios.get(`/resume_prompt_configuration`);
    return response.data;
  },

  async saveResumePromptConfiguration(data) {
    const response = await axios.put(`/resume_prompt_configuration`, data);
    return response.data;
  },

  async getJobDescriptionSuggestions(workHistoryItem) {
    const queryParams = workHistoryItem;

    const response = await axios.get(`/job_description_suggestions`, {
      params: queryParams,
    });

    return response.data;
  },

  async getJobAccomplishmentsSuggestions(workHistoryItem) {
    const queryParams = workHistoryItem;

    const response = await axios.get(`/job_accomplishments_suggestions`, {
      params: queryParams,
    });

    return response.data;
  },

  async getProfessionalSummarySuggestions(professionalSummary) {
    const queryParams = professionalSummary;

    const response = await axios.get(`/professional_summary_suggestions`, {
      params: queryParams,
    });

    return response.data;
  },

  async getProfessionalSummaryGeneration(resumeId) {
    const response = await axios.get(
      `/resume/${resumeId}/generate_professional_summary`
    );

    return response.data;
  },

  async parseResume(formData) {
    const response = await axios.post(`/resume_parser`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  },

  async downloadRenderedResume(resumeId) {
    const response = await axiosWithETAGCache.get(
      `/resume/${resumeId}/render`,
      {
        responseType: "blob",
      }
    );
    return response.data;
  },

  async downloadGeneratedResume(resumeId) {
    const response = await axios.get(`/resume/${resumeId}/generate`, {
      responseType: "blob",
    });
    return response.data;
  },

  async getResumeConfiguration() {
    const response = await axios.get(`/resume_configuration`);
    return response.data;
  },

  async saveResumeConfiguration(resumeConfiguration) {
    const response = await axios.put(
      `/resume_configuration/${resumeConfiguration._id}`,
      resumeConfiguration
    );
    return response.data;
  },
};

export default api;
